import { useState } from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AcademicsCheckoutForm from "./academics_checkout_form";
// import CheckoutForm from './checkoutForm';

const AcademicsStripePayment = ({upfront_amount_payment_indent_json, type, plan_id}) => {
    const [loader, set_loader] = useState(false);
    const [errors, set_errors] = useState([]);
    const stripePublishableKey = "pk_test_51KnovYDPuQqUp5fc7c9m7zZUh9kKKaaM9MElVyiqYHL8C4ORMJjkDBMr7ZX8eDaaMpaMAES4ytFZ1bDKB0dU3fKc006FlW1Oes";
    const stripePromise = loadStripe(stripePublishableKey);
    console.log("upfront_amount_payment_indent_json",upfront_amount_payment_indent_json);
    return(
        <>
        <br/>
         <div className='theme-content-head' style={{ marginBottom: '0px' }}>
                <div className='theme-content-left-head'>
                    <h3 style={{ marginTop: "10px" }}>Payment Details</h3>
                </div>
            </div>
            <br />
            <hr />
            <br />
            <div className="row">
                    <Elements
                        stripe={stripePromise}>
                        <AcademicsCheckoutForm set_loader={set_loader} upfront_amount_payment_indent_json = {upfront_amount_payment_indent_json}  type={type} plan_id={plan_id} />
                    </Elements>
                </div>
        </>
    );
}

export default AcademicsStripePayment;