/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import { InfoCircleOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons'
import { Button, Checkbox, notification, Spin, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import SisLoader from '../../../widgets/loader'
import { ADD_COURSE_INDENT, REMOVE_COURSE_IN_SEMESTER, SELECTED_COURSE_IN_SEMESTER, STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING } from '../../../apis/apis'
import { unit_selection_limit } from '../../common-functions'
import { useNavigate } from 'react-router-dom'

const ChooseAddCourse = () => {

  const [loader, set_loader] = useState(true);
  const [page_loader, set_page_loader] = useState(true);
  const [errors, set_errors] = useState([]);
  const [calendar_id, set_calendar_id] = useState("");
  const [semester_id, set_semester_id] = useState("");
  const [student_id, set_student_id] = useState("");
  const [general_eduction_list, set_general_eduction_list] = useState([]);
  const [concentration_list, set_concentration_list] = useState([]);
  const [submit_button_status, set_submit_button_status] = useState(true);
  const [payment_button_status, set_payment_button_status] = useState(false);
  const [submit_button_status_message, set_submit_button_status_message] = useState('');
  const [ student_semester_registration_course_listing,  set_student_semester_registration_course_listing,] = useState([]);


  const course_column = [
    {
      title: "Courses",
      render: (text, record) => {
        return (
          <>
            {record.course_semester_registration ? (
              <>
                <label style={{ position: "relative", course: "pointer" }}>
                  {record.course_semester_registration.course_status > 2 ? (
                    <>
                      <Checkbox
                        disabled
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                        }}
                        checked
                      />
                    </>
                  ) : (
                    <>
                      <Checkbox
                        checked={record.checked}
                        onChange={(e) =>
                          REMOVE_API(record?.course_semester_registration?.id)
                        }
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                        }}
                      />
                    </>
                  )}

                  <div style={{ minWidth: "350px" }}>
                    <span
                      style={{
                        fontSize: "12px",
                        paddingLeft: "22px",
                        course: "pointer",
                      }}
                    >
                      {" "}
                      {record.title}{" "}
                      <span
                        style={{
                          fontSize: "10px",
                          backgroundColor: "red",
                          color: "#fff",
                          padding: "1px 7px",
                          borderRadius: "5px",
                        }}
                      >
                        SEC-{record.section_name}
                      </span>
                      {record?.transferred_course && (
                        <>
                          <i
                            title="Transferred course"
                            style={{
                              background: "black",
                              marginLeft: "10px",
                              padding: "2px 7px",
                              color: "#fff",
                              borderRadius: "50px",
                            }}
                          >
                            T
                          </i>
                        </>
                      )}
                    </span>
                  </div>
                </label>
              </>
            ) : (
              <>
                <label style={{ position: "relative", course: "pointer" }}>
                  {record.rooms && record?.timing && record?.faculty ? (
                    <>
                      <Checkbox
                        checked={false}
                        onChange={(e) =>
                          ADD_SELECTED_COURSE_IN_SEMESTER(
                            e,
                            calendar_id,
                            semester_id,
                            student_id,
                            record.id,
                            record.section_id,
                            record.sub_section_id,
                            record?.children_id,
                            record?.rooms?.id,
                            record?.timing,
                            record?.faculty?.id,
                            1,
                            record?.transferred?.credit_earn === record?.units
                              ? 3
                              : record?.transferred?.credit_earn < record?.units
                                ? 7
                                : 1,
                            record?.units
                      
                          )
                        }
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  <div style={{ minWidth: "350px" }}>
                    <span
                      style={{
                        fontSize: "12px",
                        paddingLeft: "22px",
                        course: "pointer",
                      }}
                    >
                      {" "}
                      {record.title}{" "}
                      <span
                        style={{
                          fontSize: "10px",
                          backgroundColor: "red",
                          color: "#fff",
                          padding: "1px 7px",
                          borderRadius: "5px",
                        }}
                      >
                        SEC-{record.section_name}
                      </span>
                      {record?.transferred_course && (
                        <>
                          <i
                            title="Transferred course"
                            style={{
                              background: "black",
                              marginLeft: "10px",
                              padding: "2px 7px",
                              color: "#fff",
                              borderRadius: "50px",
                            }}
                          >
                            T
                          </i>
                        </>
                      )}
                    </span>
                  </div>
                </label>
              </>
            )}
          </>
        );
      },
    },
    {
      title: "Course Code",
      render: (text, record) => {
        return (
          <>
            <span>{record?.code}</span>
          </>
        );
      },
    },

    {
      title: "Total Credits",
      render: (text, record) => {
        return (
          <>
            <span>{record?.units}</span>
          </>
        );
      },
    },
    {
      title: "Given Credits",
      render: (text, record) => {
        // return <><span >{record?.credit_earn.toFixed(1)}</span></>;
      },
    },
    {
      title: "Room",
      render: (text, record) => {
        return (
          <>
            <span>{record?.rooms?.room_name}</span>
          </>
        );
      },
    },
    {
      title: "Timing",
      render: (text, record) => {
        var timingData = record?.timing ? JSON.parse(record.timing) : null;
        return <>{timingData && get_timing(record, timingData)}</>;
      },
    },
    {
      title: "Faculty",
      render: (text, record) => {
        return (
          <>
            <span style={{ textTransform: "capitalize" }}>
              {record?.faculty?.honorific} {record?.faculty?.name}
            </span>
          </>
        );
      },
    },
    {
      title: "Course Status",
      render: (text, record) => {
        return (
          <>
            <span>
              {record?.course_semester_registration?.course_status == 2 ? "Registered " : "Not Registered"}
            </span>
          </>
        );
      },
    },
  ];

  const navigate = useNavigate();

  const get_timing = (data, selected_days) => {
    return (
      <>
        {selected_days.map((item) => (
          <>
            {item?.start_time && (
              <>
                {data.rooms && data?.timing && data?.faculty ? (
                  <>
                    <span
                      style={{
                        width: "110px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "13px",
                      }}
                    >
                      {item?.value?.slice(0, 2).toUpperCase()}{" "}
                      {item?.start_time} - {item?.end_time}
                    </span>
                    <br />
                  </>
                ) : (
                  <>
                    <span style={{ color: "red" }}>Not Available</span>
                  </>
                )}
              </>
            )}
          </>
        ))}
      </>
    );
  };

  useEffect(() => {
    VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING();
  }, [page_loader]);
  

  const VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", USER_DATA?.id);
    FORM_DATA.append("token", USER_DATA?.token);
    FORM_DATA.append("acad_calender_id", parseInt(USER_DATA?.calendar_id));
    FORM_DATA.append("semester_id", parseInt(USER_DATA?.current_semester));
    const VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE =
      await STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING(FORM_DATA);
    if (
      VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
        ?.status
    ) {
      set_student_semester_registration_course_listing(
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data
      );
      set_semester_id(VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.semester?.id);
      set_calendar_id(VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.calendar?.id);
      set_student_id(VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.basic_information?.id);
      set_concentration_list(VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.progrma_concentration);
      set_payment_button_status(VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.payment_button_status);
      if (VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.progrma_concentration.length > 0) {
        const concentration = VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.progrma_concentration;
        const concentration_filter = concentration?.filter((item) => item?.course_semester_registration?.course_status != 2)
        set_concentration_list(concentration_filter);
      }
      if (VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.general_eduction.length > 0) {
        const general_eduction = VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.general_eduction;
        const general_eduction_filter = general_eduction?.filter((item) => item?.course_semester_registration?.course_status != 2)
        set_general_eduction_list(general_eduction_filter);
      }
      const Button_Satus = unit_selection_limit(VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.student_selected_units,
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.semester_registration,
        VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.basic_information, VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.students_programmes_registrations, VIEW_STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING_API_RESPONSE?.data?.program);
      set_submit_button_status_message(Button_Satus?.message ? Button_Satus?.message : '')
      set_submit_button_status(Button_Satus?.status ? false : true);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };

  const ADD_SELECTED_COURSE_IN_SEMESTER = async (
    e,
    calendar_id,
    semester_id,
    student_id,
    course_id,
    section_id,
    sub_section_id,
    class_section_id,
    room_id,
    timing,
    faculty,
    given_credits,
    course_status,
    course_units = 0,
  ) => {


    var check_student_selected_units = student_semester_registration_course_listing?.student_selected_units + parseInt(course_units);
    var check_unit_selection_limit =  unit_selection_limit(check_student_selected_units,
      student_semester_registration_course_listing?.semester_registration,
      student_semester_registration_course_listing?.basic_information, student_semester_registration_course_listing?.students_programmes_registrations, student_semester_registration_course_listing?.program)
      console.log('check_unit_selection_limit true', check_unit_selection_limit)
    if (check_unit_selection_limit.status) {
      set_submit_button_status_message("")
        set_loader(true);
        const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
        const FORM_DATA = new FormData();
        FORM_DATA.append("token", USER_DATA.token);
        FORM_DATA.append("student_id", student_id);
        FORM_DATA.append("calendar_id", calendar_id);
        FORM_DATA.append("semester_id", semester_id);
        FORM_DATA.append("course_id", course_id);
        FORM_DATA.append("section_id", ((section_id === null) || (section_id === undefined)) ? "" : section_id);
        FORM_DATA.append("sub_section_id", ((sub_section_id === null) || (sub_section_id === undefined)) ? "" : sub_section_id);
        FORM_DATA.append("class_section_id", ((class_section_id === null) || (class_section_id === undefined)) ? "" : class_section_id);
        FORM_DATA.append("room_id", room_id);
        FORM_DATA.append("timing", timing);
        FORM_DATA.append("faculty", faculty);
        FORM_DATA.append("given_credits", given_credits);
        FORM_DATA.append("course_status", course_status);
        FORM_DATA.append("request_type", "add_course");
        const API_RESPONSE = await SELECTED_COURSE_IN_SEMESTER(FORM_DATA);
        if (API_RESPONSE.data.status) {
          if(page_loader){
            set_page_loader(false);
          }else{
            set_page_loader(true);
          }
          // notification.open({
          //   message: "Success!!",
          //   description: "You have successfully registered the course!",
          //   icon: <SmileOutlined style={{ color: "green" }} />,
          // });
          // set_loader(false);
        }
        else {
          // notification.open({
          //   message: "Note!!",
          //   description: "You have already registered in the course!",
          //   icon: <SmileOutlined style={{ color: "yellow" }} />,
          // });
          // set_loader(false);
        }
    }else{
      console.log('check_unit_selection_limit ee', check_unit_selection_limit)
      set_submit_button_status_message(check_unit_selection_limit.message)
    }


  }

  const REMOVE_API = async (
    id,
  ) => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    const API_RESPONSE = await REMOVE_COURSE_IN_SEMESTER(FORM_DATA);

    if (API_RESPONSE?.data?.status) {
      
      if(page_loader){
        set_page_loader(false);
      }else{
        set_page_loader(true);
      }
    }
  };

  const SUBMIT_ADD_COURSE_INDENT = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("student_user_id", student_id);
    FORM_DATA.append("semester_id", semester_id);
    FORM_DATA.append("amount", 2000);
    const API_RESPONSE = await ADD_COURSE_INDENT(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      if(API_RESPONSE?.data?.fees_free){
        notification.open({
          message: "Note!!",
          description: "Add course request send successfully!",
          icon: <SmileOutlined style={{ color: "green" }} />,
        });
        navigate("/students-academic-detail");
      }else{
        navigate("/add-course-billing/" + btoa(semester_id) + "/" + btoa(student_id));
        set_loader(false);
      }
    }

  }

  return (
    <>
      {loader ? <SisLoader /> :
        <>
          <div className="theme-content-head">
            <div className="theme-content-left-head">
              <h3 style={{ color: "#4b4b4b" }}>Choose a course to add</h3>
            </div>
          </div>
          <br />
          <div>
            <div className="theme-content-left-head">
              <h4>
                Units selected for this semester :{" "}
                {student_semester_registration_course_listing?.student_selected_units + parseInt(student_semester_registration_course_listing?.student_selected_units_add_course)}
              </h4>
            </div>
            <br />
          </div>
          <div className="theme-content-left-head">
            <h3>Course Selection</h3>
            {submit_button_status_message && <>
              <h4 style={{ marginTop: "10px", color: "red" }}>
                <InfoCircleOutlined
                  style={{ marginRight: "10px", fontSize: "16px" }}
                />
                {submit_button_status_message}
              </h4>
            </>}

          </div>
          <br />
      
          {general_eduction_list?.length >
            0 && (
              <>
                <h3 className="first-letter-capitalize" style={{ color: "#3B82F6" }}>
                  General Education
                </h3>
                <br />
                <Table
                  columns={course_column}
                  dataSource={
                    general_eduction_list
                  }
                  pagination={false}
                />
              </>
            )}
          <br />
          {concentration_list?.length > 0 && (
            <>
              <h3 className="first-letter-capitalize" style={{ color: "#3B82F6" }}>
                Concentration{" "}
              </h3>
              <br />
              <h3 className="first-letter-capitalize" style={{ color: "#4b4b4b" }}>
                {
                  student_semester_registration_course_listing
                    ?.selected_concentration?.title
                }{" "}
              </h3>
              <br />
              <Table
                columns={course_column}
                dataSource={
                  concentration_list
                }
                pagination={false}
              />
            </>
          )}
          <br />
          
          <div className="row">
            <div className="col-4" style={{ width: "200px" }}>
              <div className="input-box">
                {loader ? (
                  <>
                    <Button type="primary">
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: "12px",
                              color: "#fff",
                              marginRight: "5px",
                            }}
                          />
                        }
                      />{" "}
                      Submiting
                    </Button>
                  </>
                ) : (
                  <>
                {parseInt(student_semester_registration_course_listing?.student_selected_units_add_course) > 0 ?  <Button onClick={SUBMIT_ADD_COURSE_INDENT} type="primary"
                    //  disabled={submit_button_status}
                    // disabled={!payment_button_status && submit_button_status}
                    >
                      Submit
                    </Button> : <Button type="primary"
                     disabled={true}
                    // disabled={!payment_button_status && submit_button_status}
                    >
                      Submit
                    </Button>} 
                 
                   
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}

export default ChooseAddCourse