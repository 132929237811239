import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Input, Spin, notification } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./createAccount.css";
import { CREATE_ACCOUNT } from "../../../apis/apis";

const CreateAccount = () => {
  const [username, set_username] = useState("");
  const [first_name, set_first_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [middle_name, set_middle_name] = useState("");
  const [email, set_email] = useState("");
  const [privacy_policy_check, set_privacy_policy_check] = useState(0);
  const [errors, set_errors] = useState([]);
  const [loader, set_loader] = useState(false);
  const navigate = useNavigate();

  const STUDENT_SELF_CREATE_ACCOUNT = async () => {
    set_loader(true);
    // const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    // FORM_DATA.append('token', USER_DATA.token);
    FORM_DATA.append("username", username);
    FORM_DATA.append("first_name", first_name);
    FORM_DATA.append("last_name", last_name);
    FORM_DATA.append("middle_name", middle_name);
    FORM_DATA.append("email", email);
    FORM_DATA.append("privacy_policy_check", privacy_policy_check);

    const STUDENT_SELF_CREATE_ACCOUNT_API_RESPONSE = await CREATE_ACCOUNT(
      FORM_DATA
    );

    if (STUDENT_SELF_CREATE_ACCOUNT_API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description:
          "You have successfully registered, and a password link has been sent to your email address.",
        icon: <SmileOutlined style={{ color: "green" }} />,
        duration: 10,
      });
      set_loader(false);
      navigate("/");

      // const closeNotification = () => {
      //     notification.close(key);
      // };

      // const key = `open${Date.now()}`;

      // notification.open({
      //     message: 'Success!!',
      //     description: 'You have successfully registered and a set password link has been sent to your email id.',
      //     icon: <SmileOutlined style={{ color: 'green' }} />,
      //     btn: (
      //         <Button type="primary" onClick={closeNotification}>
      //             OK
      //         </Button>
      //     ),
      //     key,
      // });
    } else {
      set_errors(STUDENT_SELF_CREATE_ACCOUNT_API_RESPONSE?.data?.errors);
      set_loader(false);
    }
  };

 // Validation function to allow only letters and spaces
const handleTextChange = (setter) => (e) => {
    const value = e.target.value;
    const onlyLettersAndSpaces = /^[A-Za-z\s]*$/; // Regular expression for letters and spaces
    
    if (onlyLettersAndSpaces.test(value)) {
      setter(value); // Set value only if it's valid
    }
  };
  return (
    <>
      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <div className="col-4">
          <div className="input-box">
            <label htmlFor="username">
              Username<i style={{ color: "red" }}> *</i>
            </label>
            <Input
              id="username"
              placeholder="Username"
              value={username}
              onChange={(e) => set_username(e.target.value)}
              autoComplete={false}
            />
            {errors?.username && (
              <>
                <span style={{ color: "red" }}>{errors?.username[0]}</span>
              </>
            )}
          </div>
          <div className="input-box">
            <label htmlFor="firstname">
              Firstname<i style={{ color: "red" }}> *</i>
            </label>
            <Input
              id="firstname"
              placeholder="First Name"
              value={first_name}
              onChange={handleTextChange(set_first_name)}
              autoComplete={false}
            />
            {errors?.first_name && (
              <>
                <span style={{ color: "red" }}>{errors?.first_name[0]}</span>
              </>
            )}
          </div>
          <div className="input-box">
            <label htmlFor="middlename">Middlename</label>
            <Input
              id="middlename"
              placeholder="Middle Name"
              value={middle_name}
              onChange={handleTextChange(set_middle_name)}
              autoComplete={false}
            />
            {errors?.middle_name && (
              <>
                <span style={{ color: "red" }}>{errors?.middle_name[0]}</span>
              </>
            )}
          </div>
          <div className="input-box">
            <label htmlFor="lastname">
              Lastname<i style={{ color: "red" }}> *</i>
            </label>
            <Input
              id="lastname"
              placeholder="Last Name"
              value={last_name}
              onChange={handleTextChange(set_last_name)}
              autoComplete={false}
            />
            {errors?.last_name && (
              <>
                <span style={{ color: "red" }}>{errors?.last_name[0]}</span>
              </>
            )}
          </div>
          <div className="input-box">
            <label htmlFor="email">
              Email<i style={{ color: "red" }}> *</i>
            </label>
            <Input
              id="email"
              placeholder="Email"
              value={email}
              onChange={(e) => set_email(e.target.value)}
              autoComplete={false}
            />
            {errors?.email && (
              <>
                <span style={{ color: "red" }}>{errors?.email[0]}</span>
              </>
            )}
          </div>
          <div className="input-box">
            <p
              onClick={() => window.open("/show-privacy-policy", "_blank")}
              className="privacy-policy"
            >
              Show privacy Policy
            </p>
          </div>
          <div>
            <Checkbox
              checked={privacy_policy_check}
              onChange={(e) =>
                e.target.checked
                  ? set_privacy_policy_check(1)
                  : set_privacy_policy_check(0)
              }
              style={{
                color: "#626262",
                fontWeight: "500",
                textTransform: "none",
              }}
            >
              Please confirm that you agree to our privacy policy
            </Checkbox>
            {errors?.privacy_policy_check && (
              <>
                <span style={{ color: "red" }}>
                  <br />
                  {errors?.privacy_policy_check}
                </span>
              </>
            )}
          </div>

          <div className="input-box" style={{ textAlign: "left" }}>
            {loader ? (
              <>
                <Button type="primary">
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: "12px",
                          color: "#fff",
                          marginRight: "5px",
                        }}
                      />
                    }
                  />{" "}
                  Signup
                </Button>
              </>
            ) : (
              <>
                <Button type="primary" onClick={STUDENT_SELF_CREATE_ACCOUNT}>
                  Sign up
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAccount;
