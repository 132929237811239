import axios from 'axios';

export const BACKEND_URL = 'https://dev.icore.uk';
//export const BACKEND_URL = 'https://uat.icore.uk';

const adminURL = 'https://dev.icore.uk/api/';
//const adminURL = 'https://uat.icore.uk/student-api/';

const adminAxios = axios.create({
    baseURL: adminURL,
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
       ' Access-Control-Allow-Origin': '*'
    },
});

export default adminAxios;
