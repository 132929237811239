
import React, { useEffect, useState } from 'react'
import SisLoader from '../../../widgets/loader';
import { useParams } from 'react-router-dom';
import DropCourseViewBilling from './addDropCourseViewBilling';
import AcademicsStripePayment from '../Billing/Payment/academics_stripe_payment';
import AddDropCourseStripePayment from './Payment/add_drop_course_stripe_payment';
import { ADD_COURSE_INDENT } from '../../../apis/apis';

const AddDropCourseBilling = () => {
    const [loader, set_loader] = useState(false);
    const[payment_indent_json,set_payment_indent_json] = useState("");

    const {sem_id,std_id} = useParams();

    useEffect(()=>{
        VIEW_ADD_DROP_COURSE_INDENT();
      },[]);
    
      const VIEW_ADD_DROP_COURSE_INDENT = async() => {
        const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
        const FORM_DATA = new FormData();
        FORM_DATA.append("token", USER_DATA.token);
        FORM_DATA.append("student_user_id", atob(std_id));
        FORM_DATA.append("semester_id", atob(sem_id));
        const API_RESPONSE = await ADD_COURSE_INDENT(FORM_DATA);
        if(API_RESPONSE?.data?.status)
        {
            set_payment_indent_json(API_RESPONSE?.data?.data?.add_course_payment?.payment_json);
            set_loader(false);
        }
    }
      
  return (
    <>
        {loader ? <SisLoader/> : <>
            <div className="row">
                <div className="col-7">
                    <DropCourseViewBilling/>
                  </div>
                <div className="col-5" style={{borderLeft:"1px solid #666"}}>
                    <AddDropCourseStripePayment payment_indent_json = {payment_indent_json} />
                </div>
            </div>
        </>}
        </>
  )
}

export default AddDropCourseBilling ;