import {
  InfoCircleFilled,
  InfoCircleOutlined,
  LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { Button, notification, Select, Spin, Table } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ADD_DROP_REQUEST_UPDATE, ADD_DROP_VIEW, REPEAT_COURSE_REQUEST, sisDateFormat } from "../../../apis/apis";
import { unit_selection_limit } from "../../common-functions";
import SisLoader from "../../../widgets/loader";

const DropCourse = () => {
  const [submit_button_status, set_submit_button_status] = useState(true);
  const [submit_button_status_message, set_submit_button_status_message] = useState('');
  const [api_response, set_api_response] = useState("");
  const [internship, set_internship] = useState("");
  const [errors, set_errors] = useState("");
  const [repeat_course_table, set_repeat_course_table] = useState([]);
  const [textareainputvalue, settextareainputvalue] = useState("");
  const [semester_id, set_semster_id] = useState("");
  const [request_date, set_request_date] = useState("");
  const [loader, set_loader] = useState(false);
  const navigate = useNavigate();
  const { c_id } = useParams();

  const columns = [
    {
      title: "Course Name",
      dataIndex: "course_title",
      key: "course_title",
    },
    {
      title: "Course Code",
      dataIndex: "course_code",
      key: "course_code",
    },
    {
      title: "Total Credits",
      dataIndex: "course_units",
      key: "course_units",
    },
    {
      title: "Attempt Credits",
      render: (text, record) => {
        return (
          <>
            {record?.given_credits > 1
              ? record?.given_credits
              : "No Attempted Credits"}
          </>
        );
      },
    },
    {
      title: "Room",
      render: (text, record) => {
        return (
          <>
            {record?.room_name} ({record?.room_capacity})
          </>
        );
      },
    },
    {
      title: "Timing",
      render: (text, record) => {
        var timingData = record?.timing ? JSON.parse(record.timing) : null;
        return <>{timingData && get_timing(record, timingData)}</>;
      },
    },
    {
      title: "Faculty",
      render: (text, record) => {
        return (
          <>
            <span style={{ textTransform: "capitalize" }}>
              {record?.faculty_name}
            </span>{" "}
          </>
        );
      },
    },
    {
      title: "Course Status",
      render: (text, record) => {
        return (
          <>
            <span style={{ textTransform: "capitalize" }}>
              {record?.course_status}
            </span>{" "}
          </>
        );
      },
    },
  ];
  const get_timing = (data, selected_days) => {
    return (
      <>
        {selected_days.map((item) => (
          <>
            {item?.start_time && (
              <>
                {data?.timing ? (
                  <>
                    <span
                      style={{
                        width: "110px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "12px",
                      }}
                    >
                      {item?.value?.slice(0, 2).toUpperCase()}{" "}
                      {item?.start_time} - {item?.end_time}
                    </span>
                    <br />
                  </>
                ) : (
                  <>
                    <span style={{ color: "red" }}>Not Available</span>
                  </>
                )}
              </>
            )}
          </>
        ))}
      </>
    );
  };

  useEffect(() => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    set_semster_id(USER_DATA.current_semester);
    ADD_ADD_DROP_REQUEST(c_id)
  }, []);


  const ADD_ADD_DROP_REQUEST = async(course_id) => {
    set_loader(true)
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", USER_DATA?.id);
    FORM_DATA.append("token", USER_DATA?.token);
    FORM_DATA.append("semester_id", USER_DATA?.current_semester);
    FORM_DATA.append("course_id", atob(course_id));
    FORM_DATA.append("request_type", "add_drop");
    const ADD_REPEAT_COURSE_REQUEST_API_RESPONSE = await REPEAT_COURSE_REQUEST(FORM_DATA);
    if (ADD_REPEAT_COURSE_REQUEST_API_RESPONSE?.data?.status) {
      VIEW_ADD_DROP();
    
  }
  }
  const VIEW_ADD_DROP = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", USER_DATA.id);
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("semester_id", USER_DATA.current_semester);
    FORM_DATA.append("acad_calender_id", USER_DATA.calendar_id);
    FORM_DATA.append("course_id", atob(c_id));
    const API_RESPONSE = await ADD_DROP_VIEW(FORM_DATA);
    if(API_RESPONSE?.data?.status)
    {
      set_api_response(API_RESPONSE?.data);
      const Button_Satus = unit_selection_limit(API_RESPONSE?.data?.student_selected_units,
        API_RESPONSE?.data?.semester_registration,
        API_RESPONSE?.data?.basic_information, API_RESPONSE?.data?.students_programmes_registrations, API_RESPONSE?.data?.program,);
      set_submit_button_status_message(Button_Satus?.message ? Button_Satus?.message : '')
      set_submit_button_status(Button_Satus?.status ? false : true);
      const createdAt = new Date(API_RESPONSE?.data?.repeat_row?.created_at);
      const dateOnly = createdAt.toISOString().split('T')[0];
      set_request_date(dateOnly);
      set_loader(false);
    }
    else{
      set_loader(false);
    }
  };

  const SUBMIT_ADD_DROP_REQUEST_UPDATE = async(course_id) => {
    set_loader(true)
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA?.token);
    FORM_DATA.append("internship", internship);
    FORM_DATA.append("id", api_response?.repeat_row?.id);
    FORM_DATA.append("comment", textareainputvalue);
    const API_RESPONSE = await ADD_DROP_REQUEST_UPDATE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Drop Course Request Sent Successfully.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
    navigate("/students-academic-detail")
    set_loader(false);
  }
  else{
    set_errors(API_RESPONSE?.data?.errors)
    set_loader(false);
  }
  }

  return (
    <>
    {loader ? <SisLoader/> :
    <>
      <div>
        <h2
          className="first-letter-capitalize"
          style={{ color: "#4b4b4b", padding: "20px 0" }}
        >
          Change in Registration
        </h2>
        <h4 style={{ color: "#8e8e8e" }}>
          Dropping a Course{" "}
          <span style={{ color: "#b64141" }}>
            Last day of add/drop without academic penalty-{" "}{sisDateFormat(api_response?.semester?.academic_penalty_start_date)}
          </span>
        </h4>
      </div>
      <br />
      <div className="row">
        <div
          className="col-3"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <p className="first-letter-capitalize">Date</p>
          <h3>
          {sisDateFormat(request_date)}
          </h3>
        </div>
        <div
          className="col-3"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <p className="first-letter-capitalize">Semester</p>
          <h3>{api_response?.semester?.title ? api_response?.semester?.title : "-"}</h3>
        </div>
        <div
          className="col-3"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <p className="first-letter-capitalize">Registration Number</p>
          <h3>
          {api_response?.semester_registration?.registration_no == "null" ? "-" : api_response?.semester_registration?.registration_no}
          </h3>
        </div>
        <div
          className="col-3"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <p className="first-letter-capitalize">Student ID</p>
          <h3>
            {api_response?.basic_information?.student_id}
          </h3>
        </div>
      </div>
      <br />
      <div className="row">
        <div
          className="col-3"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <p className="first-letter-capitalize">Legal Status</p>
          <h3>
          {api_response?.basic_information?.student_category}
          </h3>
        </div>
        <div
          className="col-3"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <p className="first-letter-capitalize">Program/Degree</p>
         <h3>{api_response?.program?.title}</h3>
        </div>
        <div
          className="col-3"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <p className="first-letter-capitalize">Financial Aid</p>
          <h3>
          {api_response?.basic_information?.financial_aid}
          </h3>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-3">
          <div className="input-box">
            <label style={{ textTransform: "none" }}>
              Are you currently registered for internship or externship only?
              <i style={{ color: "red" }}>*</i>
            </label>
            <br></br>
            <Select
              value={internship}
              style={{ width: "100%" }}
              onChange={(value) => set_internship(value)}
              placeholder="--Select options--"
            >
              <Select.Option value="1">Yes</Select.Option>
              <Select.Option value="2">No</Select.Option>
            </Select>
          
            {errors?.internship && (
              <>
               <br/><br/>
                <span style={{ color: "red" }}>{errors?.internship[0]}</span>
              </>
            )}
          </div>
        </div>
      </div>
      <br />
      <hr />
      <br />
      <div>
        <h4 style={{ color: "#8e8e8e" }}>Course you want to drop</h4>
      </div>
      <br />
      <Table
        columns={columns}
        pagination={false}
        dataSource={api_response?.drop_row}
      />
      <br />
      {api_response?.add_row?.length > 0 && 
      <>
      <div>
        <h4 style={{ color: "#8e8e8e" }}>Course you want to add</h4>
      </div>
      <br />
      <Table
        columns={columns}
        pagination={false}
        dataSource={repeat_course_table}
      />
      <br />
      </>}
      {api_response?.add_row?.length > 0 && 
      <>
      <div className="Lu_sis_">
        <div className="row" style={{ marginLeft: 0, marginBottom: "10px" }}>
          <div className="theme-content-head" style={{ marginBottom: "0px" }}>
            <div className="theme-content-left-head">
              <p
                style={{
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                {" "}
                <span
                  style={{
                    padding: "0 10px 0 0",
                    color: "#900000",
                    fontSize: "16px",
                  }}
                >
                  <InfoCircleOutlined />
                </span>
                The difference amount of $30 and any other associative fees will be adjusted in your payment plan.
              </p>
            </div>
          </div>
        </div>
        <hr />
        <h3 className="first-letter-capitalize" style={{ color: "#8E8E8E" }}>
          Cost Adjustment
        </h3>
        <div className="row" style={{ marginLeft: 0 }}>
          <div className="col-4" style={{padding:0}}>
          <div className="theme-content-head" style={{ marginBottom: "0px" }}>
              <div className="theme-content-left-head">
              <p style={{margin:"5px 0"}}>Drop course cost you paid</p>
              <p>Added course cost</p>
              <hr/>
              <p style={{color: "#4b4b4b", fontWeight: "700"}}>Difference amount to be adjusted</p>
            </div>
              <div className="theme-content-right-head">
                <p  style={{margin:"5px 0",color: "#4b4b4b", fontWeight: "600"}}>$150</p>
                <p style={{color: "#4b4b4b", fontWeight: "600"}}>$150</p>
                <hr/>
                <p style={{color: "#4b4b4b", fontWeight: "700"}}>$150</p>
              </div>
              </div>
              </div>
            </div>
          </div>
      <br />
      </>}
      <div className="row">
        <div className="col-8">
          <div className="input-box">
            <label style={{ textTransform: "none" }}>Reason to drop</label>
            <TextArea
              onChange={(e) => settextareainputvalue(e.target.value)}
              rows={6}
              autoSize={{ minRows: 4, maxRows: 4 }}
              placeholder="write reason for repeat a course..."
              maxLength={100}
            />
            <div style={{ textAlign: "right", color: "#888" }}>
              {textareainputvalue.length}/100
            </div>
          </div>
        </div>
      </div>
      <br />
      {submit_button_status &&
        <>
      <div
        className="row"
        style={{
          border: "1px solid #e1e1e1",
          borderRadius: "5px",
          margin: 0,
          padding: "10px 10px 0 10px",
        }}
      >
        <p
          style={{
            border: "1px solid #cacaca",
            background: "#FFEAEA",
            padding: "10px",
            color: "#4b4b4b",
            fontWeight: "600",
          }}
        >
          {" "}
          <span
            style={{ padding: "0 10px", color: "#900000", fontSize: "16px" }}
          >
            <InfoCircleOutlined />
          </span>
          {submit_button_status_message}
        </p>
        <br />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="col-4" style={{ width: "200px" }}>
            <div className="input-box">
              <Button
                type="primary"
                style={{ height: "40px" }}
                onClick={() =>
                  navigate("/add-course/" + btoa(semester_id))
                }
              >
                Register For Courses
              </Button>
            </div>
          </div>
        </div>
      </div>
      <br />
      </>
      }
      <div className="row" style={{ display: "flex", margin: 0, gap: "20px" }}>
          {api_response?.add_row?.length > 0 ? 
            <>
             <div className="input-box" style={{ width: "170px" }}>
              <Button
               disabled={submit_button_status}
               onClick={() => navigate("/drop-course-billing")} type="primary">
                Pay & Submit Request
              </Button>
              </div>
            </>
           : 
            <>
              <div className="input-box">
              <Button
               disabled={submit_button_status}
               onClick={SUBMIT_ADD_DROP_REQUEST_UPDATE} type="primary">
                Submit Request
              </Button>
              </div>
            </>
          }
      
        <div className="input-box" style={{ width: "100px" }}>
          <Button
            onClick={() => navigate("/students-academic-detail")}
            type="primary"
            className="cancel"
          >
            Cancel
          </Button>
        </div>
      </div>
      <br />
      <div className="row" style={{ margin: 0, lineHeight: "28px" }}>
        <p style={{ color: "#4b4b4b", fontWeight: "600" }}>
          There is a $20.00 fee for each add or drop transaction.
        </p>
        <p style={{ color: "#4b4b4b", fontWeight: "600" }}>
          {" "}
          A late fee of $50.00 will be charged for any transaction of change in
          registration.
        </p>
        <p style={{ color: "#4b4b4b", fontWeight: "600" }}>
          Please pay the necessary fees to the Accounting Office.
        </p>
        <h3 style={{ color: "#4b4b4b", fontWeight: "600" }}>
          Please read and follow the procedure below.
        </h3>
        <br />
        <h4 style={{ color: "#4b4b4b", fontWeight: "600" }}>
          PROCEDURE FOR CHANGE IN REGISTRATION
        </h4>
        <p style={{ color: "#4b4b4b", fontWeight: "600" }}>
          1. Students must complete this form including the registration number
          and student ID number. Incomplete forms will not be processed.
        </p>
        <p style={{ color: "#4b4b4b", fontWeight: "600" }}>
          2. In the Spring 2024 semester the last day classes may be
          added/dropped without academic penalty is February 5, 2024.
        </p>
        <p style={{ color: "#4b4b4b", fontWeight: "600" }}>
          3. A student’s choice of studies, once made and filed, is expected to
          be permanent for the term. The student may request registration
          changes for a fee and with a reason. The Registrar can make the change
          through the specified date indicated in the academic calendar
          (normally before the third-class meeting) if space is available and
          all prerequisites were taken, approval by the instructor is required
          after the first-class meeting.
        </p>
        <p style={{ color: "#4b4b4b", fontWeight: "600" }}>
          4. Later the specified date deadline, approvals by the instructor and
          the Provost with a written confirmation of the student’s understanding
          that missing classes may negatively influence the course final grade
          are required to add a course.
        </p>
        <p style={{ color: "#4b4b4b", fontWeight: "600" }}>
          5. A course may be dropped at any time for the same fee. Student
          withdrawing from a course before specified deadline for add/drop
          classes will get the full refund and the dropped course will be
          removed from his/her records, withdrawing after that deadline but
          before 75% of the course completion will result in “W” symbol and a
          partial tuition refund following the refund procedure. Students
          withdrawing after the 75% of the course completion and before the
          final are not eligible for a refund and will receive “Y” symbol.
          Students cannot withdraw after the final examination. International
          and financial aid students can drop courses only continuing to satisfy
          required credit loads.
        </p>
        <p style={{ color: "#4b4b4b", fontWeight: "600" }}>
          6. Fees for Change in Registration must be paid to the Accounting
          Office then this form must be returned to the Registrar’s Office.
          Students must provide their initial registration form when they submit
          this form for the payment.
        </p>
      </div>
    </>
  }
    </>
  );
};

export default DropCourse;
