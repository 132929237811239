


import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useState } from 'react'
import AddCourseCheckoutForm from './add_course_checkout_form';

const AddCourseStripePayment = ({payment_indent_json}) => {
    const [loader, set_loader] = useState(false);
    const [errors, set_errors] = useState([]);
    const stripePublishableKey = "pk_test_51KnovYDPuQqUp5fc7c9m7zZUh9kKKaaM9MElVyiqYHL8C4ORMJjkDBMr7ZX8eDaaMpaMAES4ytFZ1bDKB0dU3fKc006FlW1Oes";
    const stripePromise = loadStripe(stripePublishableKey);
  return (
    <>
    <br/>
     <div className='theme-content-head' style={{ marginBottom: '0px' }}>
            <div className='theme-content-left-head'>
                <h3 style={{ marginTop: "10px" }}>Payment Details</h3>
            </div>
        </div>
        <br />
        <hr />
        <br />
        <div className="row">
                <Elements
                    stripe={stripePromise}>
                    <AddCourseCheckoutForm set_loader={set_loader} payment_indent_json = {payment_indent_json} />
                </Elements>
            </div>
    </>
  )
}

export default AddCourseStripePayment;