import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GET_AGREEMENT } from "../../../../apis/apis";
import SisLoader from "../../../../widgets/loader";


const AgreementPreview = () => {

    const [agreement_check, set_agreement_check] = useState("");
    const [api_student_signature, set_api_student_signature] = useState("");
    const [loader, set_loader] = useState(true);
    const [errors, set_errors] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        VIEW_AGREEMENT();
    }, []);


    const VIEW_AGREEMENT = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('student_user_id', USER_DATA.id);
        const VIEW_AGREEMENT_API_RESPONSE = await GET_AGREEMENT(FORM_DATA);
        if (VIEW_AGREEMENT_API_RESPONSE?.data?.status) {
            set_loader(false);
            set_agreement_check(VIEW_AGREEMENT_API_RESPONSE?.data?.student_signature?.agreement_check);
            set_api_student_signature(VIEW_AGREEMENT_API_RESPONSE?.data?.student_signature?.student_signature);
        }
        else {
            set_loader(false);
        }
    }

    return (
        <>
            {loader ? <SisLoader />
                :
                <>
                    <div>
                        <br />
                        <hr />
                        {localStorage.getItem("payment_status") == 0 &&
                        <div className="row">
                            <div className="col-2" style={{ float: "right" }}>
                                <div className="input-box" style={{ float: "right" }}>
                                    <Button
                                     className="lusisbtn"
                                        type="primary"
                                        icon={<EditOutlined />}
                                        onClick={() => navigate("/agreement")}>
                                        Edit
                                    </Button>
                                </div>
                            </div>
                        </div>
                        }
                        <div className="theme-content-left-head">
                            <h2 style={{ color: "#626262" }}>Agreement</h2>
                        </div>
                        <br />
                    </div>
                    <p>I agree with Lincoln University’s application deadlines and admission requirements. I agree to the release of all transcripts and test scores from my previous education.</p><br />
                    <p>LI certify that the information is complete and accurate. I understand that making false statements will result in disciplinary actions, denial of admission and/or invalidation of credits or degrees earned. If admitted, I agree to abide by the policies, rules, and regulations of Lincoln University. If any information changes prior to my entry into the university, I will notify the Office of Admissions.</p><br />
                    <p>I understand that my application will not be processed without submitting of a non-refundable application fee.</p><br />

                    <div className="row" style={{ marginBottom: "0" }}>
                        <div className="col-6  first-letter-capitalize" style={{ textTransform: "none" }}>
                            <p> Do you understand and agree to the terms listed above?</p>
                            <h4 style={{ marginTop: "6px" }}>
                                {agreement_check === 1 && "Yes"}
                                {agreement_check === 0 && "No"}
                            </h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="input-box">
                                <label>
                                    Signature<i style={{ color: "red" }}>*</i>
                                </label>
                                {api_student_signature &&
                                    <div style={{ border: '1px solid', padding:"10px" }}>
                                        <img src={api_student_signature} />
                                    </div>
                                }

                            </div>
                        </div>
                    </div>

                </>
            }
        </>
    );
}

export default AgreementPreview;