import { ExclamationCircleOutlined, LoadingOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Drawer, Dropdown, Menu, notification, Popover, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router-dom";
import { REPEAT_COURSE_REQUEST } from "../../apis/apis";

const GradeTable = ({ transferred_course_item, university_course_item, repeat_course_list,setIsDropdownOpen}) => {
  const [textareainputvalue, settextareainputvalue] = useState("");
  const [transferred_course_list, set_Transferred_Course_List] = useState(
    transferred_course_item
  );
  const [university_course_list, set_University_Course_List] = useState(
    university_course_item
  );
  const [repeat_course_list_item, set_repeat_course_list_item] = useState(
    repeat_course_list
  );
  const [repeatcoursemodal, set_repeatcoursemodal] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [selectedRecord, setSelectedRecord] = useState("");
  const [loader, set_loader] = useState(false);
 
  const navigate = useNavigate();
  console.log("repeat_course_list",repeat_course_list_item);
  const transferred_course_column = [
    {
      title: "Semester",
      width: "12%",
      render: (text, record) => {
        return (
          <>
            <span>{record?.transferred_semester}</span>
          </>
        );
      },
      // sorter: (a, b) => a.transferred_semester.localeCompare(b.transferred_semester),
    },
    {
      title: "University",
      width: "12%",
      render: (text, record) => {
        return (
          <>
            <span>{record?.transferred_institution}</span>
          </>
        );
      },
      // sorter: (a, b) => a.transferred_institution.localeCompare(b.transferred_institution),
    },
    {
      title: "Courses",
      width: "40%",
      render: (text, record) => {
        return (
          <>
            <span>{record.transferred_course} (TR)</span>
          </>
        );
      },
      sorter: (a, b) =>
        a.transferred_course.localeCompare(b.transferred_course),
    },
    {
      title: "Course Code",
      width: "12%",
      render: (text, record) => {
        return (
          <>
            <span>{record?.course_code}</span>
          </>
        );
      },
    },

    {
      title: "Attempt Credit",
      width: "12%",
      render: (text, record) => {
        return (
          <>
            <span>{record?.credit_attempted.toFixed(1)}</span>
          </>
        );
      },
    },
    {
      title: "Earned Credits",
      width: "12%",
      render: (text, record) => {
        return (
          <>
            <span>{record?.credit_earn.toFixed(1)}</span>
          </>
        );
      },
    },
    {
      title: "Grade",
      width: "12%",
      render: (text, record) => {
        return (
          <>
            <span>CR</span>
          </>
        );
      },
    },
  ];
  const handleDropdownOpenChange = (open) => {
    setIsDropdownOpen(open);
  };
// Assuming university_course_item is your data source
const filteredCourses = university_course_item?.filter(
  (course) => course?.student_grade !== "-"
);


  const university_course_column = [
    {
      title: "Courses",
      width: "40%",
      render: (text, record) => {
        return (
          <>
            {record.perquisite_courses == null ||
            record.perquisite_courses == "null" ? (
              <span>{record.course_title}</span>
            ) : (
              <span>
                {record.course_title}{" "}
                <span className="perquisite-courses">P</span>
              </span>
            )}
          </>
        );
      },
      sorter: (a, b) => a.course_title.localeCompare(b.course_title),
    },
    {
      title: "Course Code",
      width: "15%",
      render: (text, record) => {
        return (
          <>
            <span>{record?.course_code}</span>
          </>
        );
      },
    },
    {
      title: "Attempt Credit",
      width: "15%",
      render: (text, record) => {
        return (
          <>
            <span>{record?.given_credits.toFixed(1)}</span>
          </>
        );
      },
    },
    {
      title: "Earned Credits",
      width: "15%",
      render: (text, record) => {
        return (
          <>
            <span>{record?.credit_earn.toFixed(1)}</span>
          </>
        );
      },
    },
    {
      title: "Grade",
      width: "15%",
      render: (text, record) => {
        return (
          <>
            <span>{record?.student_grade}</span>
          </>
        );
      },
    },
    {
      title: "Action",
      width: "15%",
      render: (text, record) => {

        // Check if the course has been requested for repeat
    const isRepeatRequested = repeat_course_list_item?.some(
      (request) =>
        request.course_id === record.course_id &&
        request.request_type === "repeat" &&
        request.approve_status !== 2 // Optional: if you want to check that it's not rejected
    );

        const handleMenuClick = (e, record) => {
          if (e.key === "1") {
            setSelectedRecord(record);
            setDrawerTitle("Repeat the course");
            setDrawerOpen(true);
          } 
          // else if (e.key === "2") {
          //   setSelectedRecord(record);
          // } else if (e.key === "3") {
          //   setSelectedRecord(record);
          // }
        };

        const items = [
          {
            key: "1",
            label: "Repeat Course",
            disabled: isRepeatRequested, // Disable the option if repeat is requested
          },
          // {
          //   key: "2",
          //   label: "Add Course",
          // },
          // {
          //   key: "3",
          //   label: "Drop Course",
          // },
        ];
        if (record?.student_grade === "R" || isRepeatRequested || record?.student_grade === "-" || record?.student_grade === "") {
          return(
          <span>-</span>
          );
        }
        return  record?.student_grade ? (
          <>
            <Dropdown
              menu={{ items, onClick: (e) => handleMenuClick(e, record) }}
              onOpenChange={handleDropdownOpenChange} // Track open/close status
              trigger={["click"]}
            >
              <span
                onClick={(e) => e.preventDefault()}
                style={{ cursor: "pointer" }}
              >
                &#8942; {/* Vertical ellipsis icon */}
              </span>
            </Dropdown>
            <Drawer
              width={600}
              title={drawerTitle}
              placement="right"
              onClose={() => setDrawerOpen(false)}
              open={drawerOpen}
            >
              {selectedRecord ? (
                <>
                  <div className="row">
                    <div className="col-3">
                      <p style={{ fontSize: "16px", fontWeight: "400" }}>
                        {selectedRecord.course_code}
                      </p>
                    </div>
                    <div className="col-9">
                      <p style={{ fontSize: "16px", fontWeight: "400" }}>
                        {selectedRecord.course_title}
                      </p>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-12">
                      <div className="input-box">
                        <label style={{ textTransform: "none" }}>
                          Reason to repeat
                        </label>
                        <TextArea
                         onChange={(e)=> settextareainputvalue(e.target.value)}
                          rows={6}
                          autoSize={{ minRows: 4, maxRows: 4 }}
                          placeholder="write reason for repeat a course..."
                          maxLength={100}
                        />
                        <div style={{ textAlign: "right", color: "#888" }}>
                          {textareainputvalue.length}/100
                        </div>
                      </div>
                    </div>
                  </div>
                  <br/>
                  <div className="row" style={{margin:"0"}}>
                    <p style={{fontSize:"18px", lineHeight:"26px"}}>If you repeat a course then the Final Grades will be recorded on the transcript and the course previously would be marked as ‘R’ and would not be a part of GPA calculation on the transcript.</p>
                  </div>
                  <div className="row">
                <div className="col-6">
                  <div className="input-box first-letter-capitalize">
                    {loader ? (
                      <>
                        <Button type="primary">
                          <Spin
                            indicator={
                              <LoadingOutlined
                                style={{
                                  fontSize: "12px",
                                  color: "#fff",
                                  marginRight: "5px",
                                }}
                              />
                            }
                          />{" "}
                          Submit request
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button onClick={()=>ADD_REPEAT_COURSE_REQUEST(selectedRecord.course_id)} type="primary">
                        Submit request
                        </Button>{" "}<Button onClick={(e) => setDrawerOpen(false)} type="primary">
                          Cancel
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
                </>
              ) : (
                <p>No data available</p>
              )}
            </Drawer>
          </>
        ) :   <span>-</span>
      },
    },
  ];


  const ADD_REPEAT_COURSE_REQUEST = async(course_id) => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", USER_DATA?.id);
    FORM_DATA.append("token", USER_DATA?.token);
    FORM_DATA.append("semester_id", USER_DATA?.current_semester);
    FORM_DATA.append("course_id", course_id);
    FORM_DATA.append("request_type", "repeat");
    FORM_DATA.append("comment", textareainputvalue);
    const ADD_REPEAT_COURSE_REQUEST_API_RESPONSE = await REPEAT_COURSE_REQUEST(FORM_DATA);
    if (ADD_REPEAT_COURSE_REQUEST_API_RESPONSE?.data?.status) {
      set_loader(false);
      notification.open({
        message: "Success!!",
        description: "Repeat Course Request Sent Successfully.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
    window.location = '/students-grade-detail';
  }
  }


  return (
    <>
      <br></br>
      {transferred_course_list?.length > 0 ? (
        <>
          <Table
            columns={transferred_course_column}
            dataSource={transferred_course_list}
            pagination={false}
            summary={(pageData) => {
              let totalAttemptedCredits = 0;
              let totalEarnedCredits = 0;

              pageData.forEach(({ credit_attempted, credit_earn }) => {
                totalAttemptedCredits += credit_attempted;
                totalEarnedCredits += credit_earn;
              });
              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <div></div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <div></div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <div></div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <div>
                      <strong>Total</strong>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <div>
                      <strong>{totalAttemptedCredits.toFixed(1)}</strong>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    <div>
                      <strong>{totalEarnedCredits.toFixed(1)}</strong>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    <div></div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
          />
          <br />
        </>
      ) : (
        <></>
      )}
      {university_course_list?.length > 0 ? (
        <>
          <Table
            columns={university_course_column}
            dataSource={filteredCourses}
            pagination={false}
            summary={(pageData) => {
              let totalAttemptedCredits = 0;
              let totalEarnedCredits = 0;

              pageData.forEach(
                ({ given_credits, credit_earn, student_grade }) => {
                  if (student_grade != "-") {
                    totalAttemptedCredits += given_credits;
                    totalEarnedCredits += credit_earn;
                  }
                }
              );
              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <div></div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <div>
                      <strong>Total</strong>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <div>
                      <strong>{totalAttemptedCredits.toFixed(1)}</strong>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <div>
                      <strong>{totalEarnedCredits.toFixed(1)}</strong>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <div></div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default GradeTable;
