import React, { useEffect, useState } from 'react'

const AddCourseViewBilling = ({payment_indent_json}) => {
   const new_payment_indent_json = JSON.parse(payment_indent_json);
   console.log(new_payment_indent_json)
  return (
    <>
      <div className="theme-content-head" style={{ marginBottom: "0px" }}>
        <div className="theme-content-left-head">
          <h3 style={{ marginTop: "10px" }}>Add Fees Payment</h3>
        </div>
      </div>
      <br />
      <hr />
      <div className="Total_Due" style={{ marginTop: "0" }}>
        <div className="billing_row">
          <span>Add Fees</span>
          ${(new_payment_indent_json?.amount/100).toFixed(2)}
        </div>
        <hr />
        <div className="billing_row">
          <h4>Total amount to be paid</h4>
          ${(new_payment_indent_json?.amount/100).toFixed(2)}
        </div>
      </div>
    </>
  )
}

export default AddCourseViewBilling;